import { Commit, Dispatch } from 'vuex';
import {
	NAMESPACE,
	ActionTypes,
	MutationTypes,
	RefreshNotice,
	NetworkStatusModal,
	LoadingStatus,
	AppState,
} from '@pixcap/ui-core/models/store/app.interface';
import { ContextMenu } from '@pixcap/ui-core/models/widgets/contextMenuManager.interface';

export const actionsWrapper = {
	async setAppTheme({ dispatch }, isLightTheme: boolean) {
		return dispatch(`${NAMESPACE}/${ActionTypes.SET_APP_THEME}`, isLightTheme, { root: true });
	},
	async fetchTopics({ dispatch }: { dispatch: Dispatch }, payload?: { origin?: string; resourceType?: string }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.GET_TOPICS}`, payload, { root: true });
	},
	async setLocale({ dispatch }, locale: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.SET_APP_LOCALE}`, locale, { root: true });
	},
};

export const mutationsWrapper = {
	setRefreshNotice({ commit }, refreshNotice: RefreshNotice | null) {
		commit(`${NAMESPACE}/${MutationTypes.SET_REFRESH_NOTICE}`, refreshNotice, { root: true }, { root: true });
	},
	setNetworkStatusModal({ commit }, networkStatusModal: NetworkStatusModal | null) {
		commit(`${NAMESPACE}/${MutationTypes.SET_NETWORK_STATUS_MODAL}`, networkStatusModal, { root: true });
	},
	setLoadingStatus({ commit }, loadingStatus: LoadingStatus | null) {
		commit(`${NAMESPACE}/${MutationTypes.SET_LOADING_STATUS}`, loadingStatus, { root: true });
	},
	setEngineLoadingStatus({ commit }, loadingStatus: LoadingStatus | null) {
		commit(`${NAMESPACE}/${MutationTypes.SET_ENGINE_LOADING_STATUS}`, loadingStatus, { root: true });
	},
	setIsWebsocketConnected({ commit }, isWebsocketConnected: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_WEBSOCKET_CONNECTED}`, isWebsocketConnected, { root: true });
	},
	setShowAppSuspense({ commit }, showAppSuspense: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_SHOW_APP_SUSPENSE}`, showAppSuspense, { root: true });
	},
	setIsAppWindowActive({ commit }, isAppWindowActive: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_APP_WINDOW_ACTIVE}`, isAppWindowActive, { root: true });
	},
	setIsReconnectingSocket({ commit }, isReconnectingSocket: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_RECONNECTING_SOCKET}`, isReconnectingSocket, { root: true });
	},
	setIsLightMode({ commit }, isLightMode: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_LIGHT_MODE}`, isLightMode, { root: true });
	},
	setIsMobileView({ commit }, isMobileView: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_MOBILE_VIEW}`, isMobileView, { root: true });
	},
	setAppNewsBanner({ commit }, banner: any) {
		commit(`${NAMESPACE}/${MutationTypes.SET_APP_NEWS_BANNER}`, banner, { root: true });
	},
	setContextMenu({ commit }, contextMenuSettings: ContextMenu) {
		commit(`${NAMESPACE}/${MutationTypes.SET_CONTEXT_MENU}`, contextMenuSettings, { root: true });
	},
	setIsFetchingTopics({ commit }: { commit: Commit }, isFetching: AppState['isFetchingTopics']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_FETCHING_TOPICS}`, isFetching, { root: true });
	},
	updateTopics({ commit }: { commit: Commit }, topics: AppState['topics']) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_TOPICS}`, topics, { root: true });
	},
	setAbTesting({ commit }: { commit: Commit }, key: string) {
		commit(`${NAMESPACE}/${MutationTypes.SET_AB_TESTING}`, key, { root: true });
	},
	setAppLocale({ commit }, locale: string) {
		commit(`${NAMESPACE}/${MutationTypes.SET_APP_LOCALE}`, locale, { root: true });
	},
};
