import { ActionTypes } from '@pixcap/ui-core/models/store/app.interface';
import { APP_DARK_THEME_KEY, APP_DATA_THEME_KEY, APP_LIGHT_THEME_KEY } from '@pixcap/ui-core/constants/app.constants';
import { mutationsWrapper } from '@pixcap/ui-core/store/app';
import { ActionContext } from 'vuex';
import { httpClient } from '@pixcap/ui-core/services/httpclient/HttpClient';
import { API_PATHS } from '@pixcap/ui-core/constants/api.constants';

const { ASSETMANAGER_PATH } = API_PATHS;

export default {
	async [ActionTypes.SET_APP_THEME]({ commit }, isLightTheme: boolean) {
		mutationsWrapper.setIsLightMode({ commit }, isLightTheme);
		if (isLightTheme) document.querySelector('html').setAttribute(APP_DATA_THEME_KEY, APP_LIGHT_THEME_KEY);
		else document.querySelector('html').setAttribute(APP_DATA_THEME_KEY, APP_DARK_THEME_KEY);
	},

	async [ActionTypes.GET_TOPICS](context: ActionContext<any, any>, payload?: { origin?: string; resourceType?: string }) {
		let params = null;
		if (payload) {
			params = { ...payload, pageSize: 50 };
		}
		mutationsWrapper.setIsFetchingTopics(context, true);
		try {
			const response = await httpClient.get(`${ASSETMANAGER_PATH}/topics`, {
				params,
			});
			if (response.data) {
				mutationsWrapper.updateTopics(context, response.data);
			}
		} catch (err) {
			mutationsWrapper.updateTopics(context, null);
		} finally {
			mutationsWrapper.setIsFetchingTopics(context, false);
		}
	},

	async [ActionTypes.SET_APP_LOCALE]({ commit }, locale: string) {
		mutationsWrapper.setAppLocale({ commit }, locale);
	},
};
