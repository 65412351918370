import { AppState, MutationTypes, RefreshNotice, NetworkStatusModal, LoadingStatus } from '@pixcap/ui-core/models/store/app.interface';
import ABTestCases from '@pixcap/ui-core/static/app-segments.json';
import { ContextMenu } from '@pixcap/ui-core/models/widgets/contextMenuManager.interface';

export default {
	[MutationTypes.SET_REFRESH_NOTICE](state: AppState, refreshNotice: RefreshNotice | null) {
		state.refreshNotice = refreshNotice;
	},
	[MutationTypes.SET_NETWORK_STATUS_MODAL](state: AppState, networkStatusModal: NetworkStatusModal | null) {
		state.networkStatusModal = networkStatusModal;
	},
	[MutationTypes.SET_LOADING_STATUS](state: AppState, loadingStatus: LoadingStatus | null) {
		state.loadingStatus = loadingStatus;
	},
	[MutationTypes.SET_ENGINE_LOADING_STATUS](state: AppState, loadingStatus: LoadingStatus | null) {
		state.engineLoadingStatus = loadingStatus;
	},
	[MutationTypes.SET_IS_WEBSOCKET_CONNECTED](state: AppState, isWebsocketConnected: boolean) {
		state.isWebsocketConnected = isWebsocketConnected;
	},
	[MutationTypes.SET_SHOW_APP_SUSPENSE](state: AppState, showAppSuspense: boolean) {
		state.showAppSuspense = showAppSuspense;
	},
	[MutationTypes.SET_IS_APP_WINDOW_ACTIVE](state: AppState, isAppWindowActive: boolean) {
		state.isAppWindowActive = isAppWindowActive;
	},
	[MutationTypes.SET_IS_RECONNECTING_SOCKET](state: AppState, isReconnectingSocket: boolean) {
		state.isReconnectingSocket = isReconnectingSocket;
	},
	[MutationTypes.SET_IS_LIGHT_MODE](state: AppState, isLightMode: boolean) {
		state.isLightMode = isLightMode;
	},
	[MutationTypes.SET_IS_MOBILE_VIEW](state: AppState, isMobileView: boolean) {
		state.isMobileView = isMobileView;
	},
	[MutationTypes.SET_APP_NEWS_BANNER](state: AppState, banner: any) {
		state.newsbanner = banner;
	},
	[MutationTypes.SET_CONTEXT_MENU](state: AppState, contextMenuSettings: ContextMenu) {
		state.contextMenu = contextMenuSettings;
	},
	[MutationTypes.SET_AB_TESTING](state: AppState, key: string) {
		if (ABTestCases) {
			state.abTesting = ABTestCases.find((item) => item.id === key);
		}
	},
	[MutationTypes.SET_IS_FETCHING_TOPICS](state: AppState, isFetching: boolean) {
		state.isFetchingTopics = isFetching;
	},
	[MutationTypes.UPDATE_TOPICS](state: AppState, payload) {
		const { content, page, totalItems, totalPages } = payload;
		let populated = {
			page,
			totalItems,
			totalPages,
		};
		content.forEach((item) => {
			populated[item.resourceTypeKey] = [];
		});
		content.forEach((item) => {
			populated[item.resourceTypeKey].push(item);
		});
		state.topics = populated;
	},
	[MutationTypes.SET_APP_LOCALE](state: AppState, locale: string) {
		state.locale = locale;
	},
};
