import { AppState } from '@pixcap/ui-core/models/store/app.interface';

export const getDefaultState = function (): AppState {
	return {
		locale: null,
		refreshNotice: null,
		networkStatusModal: null,
		loadingStatus: null, // {message: string, progress: string}
		engineLoadingStatus: null,
		isWebsocketConnected: false,
		showAppSuspense: false,
		isAppWindowActive: true,
		isReconnectingSocket: false,
		isLightMode: false,
		isMobileView: false,
		showLayoutFooter: true,
		showLayoutHeader: true,
		isLightTheme: true, // CYRIL DODO,
		showPixRenderAnnouncement: false,
		contextMenu: {
			showContextMenu: false,
			menuPos: undefined,
			menuOptions: undefined,
		},
		// newsbanner: {
		// 	description: `We have extended the Valentine's Design Contest until 20 February 2023.`,
		// 	link: null,
		// 	action: 'Join now and win awesome prizes',
		// },
		newsbanner: null,
		topics: {
			page: null,
			totalPages: null,
			totalItems: null,
		},
		isFetchingTopics: false,
		abTesting: null,
	};
};

export default (): AppState => getDefaultState();
