import { IAuthState, MutationTypes } from '@pixcap/ui-core/models/store/auth.interface';

export default {
	[MutationTypes.STORE](state, { idToken }) {
		state.idToken = idToken;
	},
	[MutationTypes.REFRESH](state, { idToken }) {
		state.idToken = idToken;
	},

	[MutationTypes.LOGOUT](state) {
		state.idToken = '';
	},
	[MutationTypes.UPDATE_SUBMIT_STATUS](state, isSubmitting) {
		state.isSubmitting = isSubmitting;
	},
	[MutationTypes.SET_ANONYMOUS_SESSION]: (state: IAuthState, anonymousSession) => {
		state.anonymousSession = anonymousSession;
	},
	[MutationTypes.SET_IS_REFRESHING_TOKEN](state, isRefreshingToken) {
		state.isRefreshingToken = isRefreshingToken;
	},
};
