import type { Commit, Dispatch } from 'vuex';
import { NAMESPACE, ActionTypes, AuthInfo, MutationTypes, IAuthState } from '@pixcap/ui-core/models/store/auth.interface';

export const actionsWrapper = {
	async refresh({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.REFRESH}`, undefined, { root: true });
	},
	async logout({ dispatch }: { dispatch: Dispatch }, payload?: { sessionChange: boolean }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.LOGOUT}`, payload, { root: true });
	},
	async resendEmailVerification({ dispatch }: { dispatch: Dispatch }, userNameOrEmail: string) {
		return dispatch(`${NAMESPACE}/${ActionTypes.RESEND_EMAIL_VERIFICATION}`, userNameOrEmail, { root: true });
	},
	async verifyEmail({ dispatch }: { dispatch: Dispatch }, payload: { email: string; verificationKey: string }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.VERIFY_EMAIL}`, payload, { root: true });
	},
	async changePassword({ dispatch }: { dispatch: Dispatch }, payload: { oldPassword: string; newPassword: string }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.CHANGE_PASSWORD}`, payload, { root: true });
	},
	async store({ dispatch }: { dispatch: Dispatch }, tokenPayload: AuthInfo) {
		return dispatch(`${NAMESPACE}/${ActionTypes.STORE}`, tokenPayload, { root: true });
	},
	async signIn({ dispatch }: { dispatch: Dispatch }, payload: { email: string; password: string }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.SIGN_IN}`, payload, { root: true });
	},
	async signUp({ dispatch }: { dispatch: Dispatch }, payload: { email: string; password: string }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.SIGN_UP}`, payload, { root: true });
	},
	async forgetPassword({ dispatch }: { dispatch: Dispatch }, payload: { email: string; passUrl?: boolean }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FORGET_PASSWORD}`, payload, { root: true });
	},
	async resetPassword({ dispatch }: { dispatch: Dispatch }, payload: { email: string; confirmationCode: string; password: string }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.RESET_PASSWORD}`, payload, { root: true });
	},
	async sendAuthCode({ dispatch }: { dispatch: Dispatch }, payload: { code: string; provider: string; codeType?: string; anonymousUser?: string }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.SEND_AUTH_CODE}`, payload, { root: true });
	},
	async validateReferralCode({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.VALIDATE_REFERRAL_CODE}`, { root: true });
	},
	async validateReferralEmail({ dispatch }: { dispatch: Dispatch }, payload: { email: string }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.VALIDATE_REFERRAL_EMAIL}`, payload, { root: true });
	},
	async fetchInvitedReferee({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_INVITED_REFEREE}`, { root: true });
	},
	async fetchPromotions({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.FETCH_PROMOTIONS}`, { root: true });
	},
	async syncAnonymousSessionWithUser({ dispatch }: { dispatch: Dispatch }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.SYNC_ANONYMOUS_SESSION_WITH_USER}`, { root: true });
	},
	async grantFigmaAccess({ dispatch }: { dispatch: Dispatch }, payload: { clientId: string; userId: string }) {
		return dispatch(`${NAMESPACE}/${ActionTypes.GRANT_FIGMA_ACCESS}`, payload, { root: true });
	},
};

export const mutationsWrapper = {
	store({ commit }: { commit: Commit }, payload: { idToken: string; userId: string }) {
		commit(`${NAMESPACE}/${MutationTypes.STORE}`, payload, { root: true });
	},
	refresh({ commit }: { commit: Commit }, payload: { idToken: string }) {
		commit(`${NAMESPACE}/${MutationTypes.REFRESH}`, payload, { root: true });
	},
	logout({ commit }: { commit: Commit }) {
		commit(`${NAMESPACE}/${MutationTypes.LOGOUT}`, undefined, { root: true });
	},
	updateSubmitStatus({ commit }: { commit: Commit }, isSubmitting: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.UPDATE_SUBMIT_STATUS}`, isSubmitting, { root: true });
	},
	setAnonymousSession({ commit }: { commit: Commit }, anonymousSession: IAuthState['anonymousSession']) {
		commit(`${NAMESPACE}/${MutationTypes.SET_ANONYMOUS_SESSION}`, anonymousSession, { root: true });
	},
	setIsRefreshingToken({ commit }: { commit: Commit }, isRefreshingToken: boolean) {
		commit(`${NAMESPACE}/${MutationTypes.SET_IS_REFRESHING_TOKEN}`, isRefreshingToken, { root: true });
	},
};
